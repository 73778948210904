import type { DialogContentProps } from '@radix-ui/react-dialog'
import * as RadixDialog from '@radix-ui/react-dialog'
import type { VariantProps } from 'class-variance-authority'
import { cva, cx } from 'class-variance-authority'
import { motion } from 'framer-motion'
import { forwardRef, useEffect, useState } from 'react'

import { IconButton } from './IconButton'

const dialogMaxWidth = cva('', {
  variants: {
    maxWidth: {
      lg: 'max-w-lg',
      xl: 'max-w-xl'
    }
  },
  defaultVariants: {
    maxWidth: 'lg'
  }
})

const Root: React.FC<RadixDialog.DialogProps> = (props) => {
  // This terrible hack is needed to prevent hydration errors.
  // The Radix Dialog is not rendered correctly server side, so we need to prevent it from rendering until the client side hydration is complete (and `useEffect` is run).
  // The issue is reported here: https://github.com/radix-ui/primitives/issues/1386
  const [open, setOpen] = useState<boolean | undefined>(props.open === undefined ? undefined : false)

  useEffect(() => {
    setOpen(props.open)
  }, [props.open, setOpen])

  return <RadixDialog.Root {...props} open={open} />
}

const Close = RadixDialog.Close

const Trigger = RadixDialog.Trigger

const Content = forwardRef<
  HTMLDivElement,
  DialogContentProps & { noPadding?: boolean; overlayClassName?: string; disableMountTransition?: boolean } & VariantProps<
      typeof dialogMaxWidth
    >
>(({ noPadding, maxWidth, overlayClassName, disableMountTransition, ...props }, ref) => {
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay asChild key='content'>
        <motion.div
          className={cx('fixed inset-0 z-50 bg-black/50', overlayClassName)}
          data-test-id='background'
          {...(disableMountTransition
            ? {}
            : {
                initial: { opacity: 0 },
                animate: { opacity: 1 },
                exit: { opacity: 0 },
                transition: { duration: 0.2 }
              })}
        />
      </RadixDialog.Overlay>
      <RadixDialog.Content
        ref={ref}
        className={cx(
          'fixed left-[50%] top-[50%] z-50 w-[95vw] -translate-x-[50%] -translate-y-[50%] rounded-md bg-white shadow-md md:w-full',
          {
            'p-6': !noPadding
          },
          dialogMaxWidth({ maxWidth })
        )}
        // aria-labelledby already used. this removes the redundant readout of screen readers
        aria-describedby={undefined}
        aria-modal='true'
        {...props}
      >
        {props.children}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  )
})

Content.displayName = 'Dialog.Content'

const Title: React.FC<RadixDialog.DialogTitleProps> = ({ className, ...props }) => {
  return (
    <RadixDialog.Title className={cx('mb-4 font-brand text-2xl text-primary', className)} data-test-id='title' {...props}>
      {props.children}
    </RadixDialog.Title>
  )
}

const CloseCross: React.FC<RadixDialog.DialogCloseProps> = (props) => {
  return (
    <RadixDialog.Close asChild {...props}>
      <IconButton className='absolute right-1.5 top-1.5' icon='Close' ariaLabel='Close dialog' iconSize='sm' variant='text' color='gray' />
    </RadixDialog.Close>
  )
}

export const Dialog = {
  Root,
  Content,
  Title,
  CloseCross,
  Close,
  Trigger
}
